
import { Component, Vue } from 'vue-property-decorator'
import { monthFormat } from '@/utils/formatDate'

@Component
export default class Xxx extends Vue {
  private tableData = {
    loading: false,
    tr: [
      {
        label: '考评月份',
        prop: 'yearMonth',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '综合得分',
        prop: 'deduction',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '当月排名',
        prop: 'ranking',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private info = {
    yearMonth: '',
    projectName: ''
  }

  page = 1
  size = 10
  total = 0

  created () {
    const date = new Date()
    this.info.yearMonth = monthFormat(date.getTime())
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.inspection.selectProjectEvaluation, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
